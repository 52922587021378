export const environment = {
    production: true,
    env: 'prod',
    debug: false,
    release: require('package.json').version,
    apiUrl: 'https://managician-api.axiocode.com',
    url: 'https://managician.axiocode.com',
    httpCacheTTL: 60000,
    adobeEmbedPdfApiKey: '4c05a08f65f1484d96c7054c8096a7ed',
    googleClientId: '480009071977-qm37tbh5gs1k84lejefjfou7rghi7jhp.apps.googleusercontent.com',
    optionalModules: [],
    authCookieDomain: 'axiocode.com',
};
